<template>
  <form @submit.prevent="sendToAuthService('SUBMIT')">
    <fieldset>
      <legend class="text-lg text-secondary-light leading-snug mb-20">
        <slot :email="authState.context.email" />
      </legend>
      <SmoothReflow tag="strong" :options="{ ...heightReflowOptions, transitionEvent: { propertyName: 'opacity' } }"
        class="block">
        <Transition name="transition-fade-slow">
          <div v-if="authState.matches('serviceError')"
            class="flex items-center bg-danger-light py-15 px-15 mb-20 border border-gray-200 rounded-lg tablet:px-20">
            <IconCloseCircle
              class="icon-close-circle flex-shrink-0 h-35 w-35 text-danger mr-15 tablet:h-35 tablet:mr-20" />
            <p class="font-medium text-base text-danger-dark leading-tight">
              {{ $t(`firebase_service.error_codes.${authState.context.serviceError.code }`) }}
            </p>
          </div>
        </Transition>
      </SmoothReflow>
      <label v-if="type !== 'set-new-password'" class="flex flex-col">
        <span class="field-label">{{$t('order_header.email')}}</span>
        <input v-model="authState.context.email" type="email" placeholder="ihre@email.ch" class="input-base" :class="[
          authState.matches('emailError') && 'input-error',
          authState.matches('awaitingSignInResponse') ? 'pointer-events-none opacity-50' : '',
        ]" @input="sendToAuthService('EMAIL_INPUT', { data: $event.target.value })"
          @blur="sendToAuthService('EMAIL_BLUR')">
        <SmoothReflow tag="strong" :options="{ ...heightReflowOptions, transitionEvent: { propertyName: 'opacity' } }"
          class="field-error">
          <Transition name="transition-fade-slow">
            <span v-if="authState.matches('emailError.invalidFormat')">
              {{ $t('errors.emailInvalidFormat') }}
            </span>
            <span v-else-if="authState.matches('emailError.isEmpty')">
              {{ $t('errors.emailRequired') }}
            </span>
            <span v-else-if="authState.matches('emailError.accountNotFound')">
              {{ $t('errors.accountNotFound') }}
            </span>
          </Transition>
        </SmoothReflow>
      </label>
      <label v-if="type !== 'request-new-password'" class="flex flex-col mt-8">
        <span class="field-label">
          {{ type === 'set-new-password' ? 'Neues' : '' }}
          {{$t('signin.password')}}
        </span>
        <input v-model="authState.context.password" type="password" placeholder="**********"
          :disabled="authState.matches('serviceError.invalidResetCode')" class="input-base" :class="[
            authState.matches('passwordError') && 'input-error',
            authState.matches('awaitingSignInResponse') ? 'pointer-events-none opacity-50' : '',
          ]" @input="sendToAuthService('PASSWORD_INPUT', { data: $event.target.value })"
          @blur="sendToAuthService('PASSWORD_BLUR')">
        <!-- <input
          v-if="type === 'set-new-password'"
          v-model="authState.context.repeatPassword"
          type="password"
          placeholder="Neues Passwort bestätigen"
          :disabled="authState.matches('serviceError.invalidResetCode')"
          class="input-base"
          :class="[
            authState.matches('passwordError') && 'input-error',
            authState.matches('awaitingSignInResponse') ? 'pointer-events-none opacity-50' : '',
          ]"
          @input="sendToAuthService('REPEAT_PASSWORD_INPUT', { data: $event.target.value })"
          @blur="sendToAuthService('REPEAT_PASSWORD_BLUR')"
        > -->
        <SmoothReflow tag="strong" :options="{ ...heightReflowOptions, transitionEvent: { propertyName: 'opacity' } }"
          class="field-error">
          <Transition name="transition-fade-slow">
            <span v-if="authState.matches('passwordError.isEmpty')">
              {{ $t('errors.passwordRequired') }}
            </span>
            <span v-if="authState.matches('passwordError.tooShort')">
              {{ $t('errors.passwordTooShort') }}
            </span>
            <!-- <span v-if="authState.matches('passwordError.isInvalidPassword')">
              {{ $t('errors.passwordFormatIsInvalid') }}
            </span>
            <span v-if="authState.matches('passwordError.isMismatch')">
              {{ $t('errors.passwordpasswordMismatch') }}
            </span> -->
            <span v-else-if="authState.matches('passwordError.wrongPassword')">
              {{ $t('errors.passwordIsWrong') }}
            </span>
          </Transition>
        </SmoothReflow>
      </label>
      <div class="flex flex-col items-center mt-20 tablet:flex-row">
        <button type="submit" :disabled="buttonPrimaryIsDisabled" class="button button-primary button-filled w-full flex items-center justify-center h-42
          tablet:w-auto tablet:px-35" @mousedown.prevent>
          <!-- @mousedown.prevent - Force the submit button press to be taken into account instead of blur
          and other events  -->
          <slot name="button-primary" />
        </button>
        <RouterLink :to="secondaryButtonRoute" class="button button-secondary button-text w-full flex items-center justify-center py-8 mt-10 tablet:w-auto
          tablet:px-40 tablet:mt-0">
          <slot name="button-secondary" />
        </RouterLink>
      </div>
    </fieldset>
  </form>
</template>


<script>
import { useMachine } from '@xstate/vue';
import { Machine, assign } from 'xstate';
import IconCloseCircle from '@/components/icon/IconCloseCircle';
import SmoothReflow from '@/components/SmoothReflow';
import { heightReflowOptions } from '@/helpers/reflowOptions';
import { FBAuth } from '@/helpers/firebaseServices';
import { defaultLocale } from '@/i18n';


const emailRegex = /^.+@.+\..+$/;
// const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

const isTooShort = (password, length) => password.length < length;
const isValidEmailFormat = (email) => emailRegex.test(email);
// const isValidPasswordFormat = (password) => passwordRegex.test(password);


const authMachineGuards = {
  emailIsEmpty: (context) => !context?.email?.length,
  emailFormatIsInvalid: (context) => !isValidEmailFormat(context?.email),
  passwordIsEmpty: (context) => !context?.password?.length,
  passwordTooShort: (context) => isTooShort(context?.password, 6),
  // passwordFormatIsInvalid: (context) => !isValidPasswordFormat(context?.password),
  // passwordMismatch: (context) => context?.password !== context?.repeatPassword,
  providerIsAvailable: (_, event) => event?.data?.length,
  providerIsNotAvailable: (_, event) => !event?.data?.length,
  serviceErrorAccountNotFound: (_, event) => event?.data?.code === 'auth/user-not-found',
  serviceErrorInvalidEmailFormat: (_, event) => event?.data?.code === 'auth/invalid-email',
  serviceErrorWrongPassword: (_, event) => event?.data?.code === 'auth/wrong-password',
};


export default {
  props: {
    machineConfig: {
      type: Object,
      required: true,
    },
    initialMachineContext: {
      type: Object,
      default: undefined,
    },
    type: {
      type: String,
      default: 'sign-in',
      validator(value) {
        return ['sign-in', 'request-new-password', 'set-new-password'].includes(value);
      },
    },
    locale: {
      type: String,
      default: defaultLocale
    },
  },
  components: {
    IconCloseCircle,
    SmoothReflow,
  },
  setup(props, { emit }) {
    const authMachine = Machine({
      ...props.machineConfig,
    }, {
      guards: {
        ...authMachineGuards,
      },
      actions: {
        saveEmail: assign((context, event) => ({
          email: event.data,
        })),
        resetPassword: assign(() => ({
          password: '',
        })),
        savePassword: assign((context, event) => ({
          password: event.data,
        })),
        saveServiceError: assign((context, event) => ({
          serviceError: {
            code: event?.data?.code,
            message: event?.data?.message,
          },
        })),
      },
      services: {
        fetchSignInMethodsForEmail: (context) => FBAuth.fetchSignInMethodsForEmail(context.email),
        signInWithEmailPassword: (context) => FBAuth.signInWithEmailAndPassword(context.email, context.password),
        sendPasswordResetEmail: (context) => {
          FBAuth.languageCode = props.locale;
          // console.log(context.locale, )
          return FBAuth.sendPasswordResetEmail(context.email)
        },
        verifyPasswordResetCode: (context) => FBAuth.verifyPasswordResetCode(context.actionCode),
        confirmPasswordReset: (context) => FBAuth.confirmPasswordReset(context.actionCode, context.password),
      },
    });
    const { state, send, service } = useMachine(authMachine, {
      ...props.initialMachineContext !== undefined && { context: props.initialMachineContext },
    });

    // Watch when final state was reached
    service.subscribe((newState) => {
      if (newState.done) emit('successful-submit', newState.context.email);
    });

    return {
      authState: state,
      sendToAuthService: send,
    };
  },
  data: () => ({
    heightReflowOptions: Object.freeze(heightReflowOptions),
  }),
  computed: {
    buttonPrimaryIsDisabled() {
      if (this.type === 'sign-in') {
        return this.authState.matches('emailError') || this.authState.matches('passwordError')
          || this.authState.matches('awaitingSignInResponse');
      }
      if (this.type === 'set-new-password') {
        return this.authState.matches('serviceError.invalidResetCode');
      }
      return false;
    },
    secondaryButtonRoute() {
      if (this.type === 'sign-in') return { name: 'RequestNewPassword', params: { locale: this.$i18n.locale } };
      return { name: 'SignIn', params: { locale: this.$i18n.locale } };
    },
  },
};
</script>


<style>
.icon-close-circle {
  & .secondary {
    @apply fill-danger-light;
  }
}

.field-label {
  @apply font-semibold tracking-wide select-none;
}

.input-base {
  @apply h-42 px-12 tracking-wide border-2 border-gray-300 rounded transition-colors duration-100 ease-in-out-quart;

  &:not([disabled]):hover {
    @apply border-gray-700;
  }

  &:not([disabled]):focus {
    @apply outline-none border-gray-700;
  }
}

.input-error:not([disabled]) {
  @apply border-2 border-danger rounded;

  &:hover,
  &:focus {
    @apply border-danger;
  }
}

.field-error {
  @apply font-semibold text-sm text-danger leading-tight mt-4;
}
</style>
